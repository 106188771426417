import { defineStore } from "pinia";
import { apiHttp } from '../../api/axiosApi'
export const useStudentStore = defineStore("student", {
    state: () => ({
        students: {
            data: [],
            per_page: 10,
            total: 0
        },
        studentsAll: [],
        studentVisitsToday: {
            data: [],
            per_page: 10,
            total: 0
        },
        lastStudentVisitsTodayFromGrade: [],
        studentVisitsAll: {
            data: [],
            per_page: 10,
            total: 0
        }

    }),
    actions: {
        async getStudents(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/students?${queryParams}`)
                if (response.status === 200) {
                    if(params.all){
                        this.studentsAll = response.data.map(student => {
                            return {
                                ...student,
                                label: `${student.name} ${student.lastname} - ${student.rut}`
                            }
                        })
                    }else if(!params.allExcel){
                        this.students = response.data
                    } 
                    return response
                }
            } catch (error) {
                return error.response
            }
        },
        setNewStudent(student) {
            this.studentsAll.unshift({...student, label: `${student.name} ${student.lastname} - ${student.rut}`})
        },
        async createStudent(data) {
            try {
                const response = await apiHttp('POST', `/students`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async updateStudent(id, data) {
            try {
                const response = await apiHttp('PUT', `/students/${id}`, data)
                return response
            } catch (error) {
                return error
            }
        },
        async deleteStudent(id) {   
            try {
                const response = await apiHttp('DELETE', `/students/${id}`)
                return response
            } catch (error) {
                return error
            }
        },
        async getStudentVisitsToday(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/student/visits/today?${queryParams}`)
                if (response.status === 200) {
                    this.studentVisitsToday = response.data
                    return response
                }
            } catch (error) {
                return error
            }
        },
        async getLastStudentVisitsTodayFromGrade(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/student/visits/last/from-grade/today?${queryParams}`)
                if (response.status === 200) {
                    this.lastStudentVisitsTodayFromGrade = response.data
                    return response
                }
            } catch (error) {
                return error.response
            }
        },   
        async registerVisitStudent(data) {
            try {
                const response = await apiHttp('POST', `/register/student-visit`, data)
                return response
            } catch (error) {
                return error
            }
        },  
        async getStudentVisitsAll(params) {
            let queryParams = new URLSearchParams(params).toString()
            try {
                const response = await apiHttp('GET', `/student/visits/all?${queryParams}`)
                if (response.status === 200 && !params.all) {
                    this.studentVisitsAll = response.data
                }
                return response
            } catch (error) {
                return error.response
            }
        }, 
    },
})